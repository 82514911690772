<template>
	<form
		class="main-wrapper form-group-image mt-4"
		@submit.prevent="handleSubmit"
	>
		<CRow>
			<CCol md="12">
				<h4 class="mb-4">
					General Info
				</h4>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12">
				<div class="label mb-2">
					Warehouse/branch Logo
				</div>
			</CCol>
			<CCol>
				<BaseInputImage
					:thumbnail="showLogo(generalInfo.logo)"
					class="mb-3"
					@onClick="$refs['media-library'].open()"
					@onDelete="handleDeleteLogo"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12">
				<CInput
					v-model.trim="$v.generalInfo.nameEn.$model"
					:is-valid="!$v.generalInfo.nameEn.$error && null"
					:invalid-feedback="$t('global.error.required')"
					type="text"
					label="Warehouse/branch name (EN)*"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12">
				<CInput
					v-model.trim="$v.generalInfo.nameTh.$model"
					:is-valid="!$v.generalInfo.nameTh.$error && null"
					:invalid-feedback="$t('global.error.required')"
					type="text"
					label="Warehouse/branch name (TH)*"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="6">
				<CInput
					v-model.trim="$v.generalInfo.branchId.$model"
					:is-valid="!$v.generalInfo.branchId.$error && null"
					:invalid-feedback="branchIdErrors"
					type="text"
					label="Com7 branch ID*"
				/>
			</CCol>
			<CCol md="6">
				<BaseDropDown
					v-model="$v.generalInfo.warehouseType.$model"
					:options="warehouseTypeOptions"
					:is-valid="!$v.generalInfo.warehouseType.$error"
					:invalid-feedback="$t('global.error.required')"
					placeholder="Select warehouse type"
					label="name"
					label-drop-down="Brand type*"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12">
				<BaseMediaSelectorBox
					ref="image"
					v-model="file"
					title="Store Image"
					no-image-text="No content block"
					show-helper
					width="680"
					height="300"
					class="mt-4"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12">
				<div class="form-group form-group-switch mt-1">
					<label class="toggle-label" for="status">Status</label>
					<CSwitch
						id="status"
						:checked.sync="generalInfo.isActive"
						variant="3d"
						size="sm"
						class="switch-custom"
						color="success"
					/>
					<span>{{ generalInfo.isActive ? 'Active' : 'Inactive' }}</span>
				</div>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12">
				<hr class="my-4">
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12">
				<h4 class="mb-4 mt-3">
					Address info
				</h4>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12">
				<CInput
					v-model.trim="$v.addressInfo.addressEn.$model"
					:is-valid="!$v.addressInfo.addressEn.$error && null"
					:invalid-feedback="$t('global.error.required')"
					type="text"
					label="Address (EN)*"
					description=" Warehouse/branch place no., road/soi"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12">
				<CInput
					v-model.trim="$v.addressInfo.addressTh.$model"
					:is-valid="!$v.addressInfo.addressTh.$error && null"
					:invalid-feedback="$t('global.error.required')"
					type="text"
					label="Address (TH)*"
					description=" Warehouse/branch place no., road/soi"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="6">
				<CInput
					v-model.trim="$v.addressInfo.postcode.$model"
					:is-valid="!$v.addressInfo.postcode.$error && null"
					:invalid-feedback="$t('global.error.required')"
					type="text"
					label="Postcode*"
				/>
			</CCol>
			<CCol md="6">
				<BaseDropDown
					v-model.trim="$v.addressInfo.province.$model"
					:options="provinceOptions"
					:is-valid="!$v.addressInfo.province.$error"
					:disabled="!provinceOptions.length > 0"
					invalid-feedback="Selection required"
					class="multiple-options"
					label="name"
					label-drop-down="Province*"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="6">
				<BaseDropDown
					v-model.trim="$v.addressInfo.district.$model"
					:options="districtOptions"
					:is-valid="!$v.addressInfo.district.$error"
					:disabled="!districtOptions.length > 0"
					invalid-feedback="Selection required"
					class="multiple-options"
					label="name"
					label-drop-down="District*"
				/>
			</CCol>
			<CCol md="6">
				<BaseDropDown
					v-model.trim="$v.addressInfo.subdistrict.$model"
					:options="subdistrictOptions"
					:is-valid="!$v.addressInfo.subdistrict.$error"
					:disabled="!subdistrictOptions.length > 0"
					invalid-feedback="Selection required"
					class="multiple-options"
					label="name"
					label-drop-down="Sub district*"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="6">
				<CInput
					v-model.trim="$v.addressInfo.phone.$model"
					:is-valid="!$v.addressInfo.phone.$error && null"
					:invalid-feedback="$t('global.error.required')"
					type="text"
					label="Phone number*"
				/>
			</CCol>
			<CCol md="6">
				<CInput
					v-model.trim="addressInfo.deliveryPhone"
					:is-valid="!$v.addressInfo.deliveryPhone.$error && null"
					:invalid-feedback="deliveryPhoneError"
					type="text"
					label="Delivery phone number*"
					maxlength="10"
					@keypress="onValidateNumber"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12">
				<CInput
					v-model.trim="$v.addressInfo.email.$model"
					:is-valid="!$v.addressInfo.email.$error && null"
					:invalid-feedback="$t('global.error.emailInvalidFormat')"
					type="text"
					label="Email"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="6">
				<CInput
					v-model.trim="$v.addressInfo.latitude.$model"
					:is-valid="!$v.addressInfo.latitude.$error && null"
					invalid-feedback="Should between -90 to 90"
					type="text"
					label="Latitude"
				/>
			</CCol>
			<CCol md="6">
				<CInput
					v-model.trim="$v.addressInfo.longitude.$model"
					:is-valid="!$v.addressInfo.longitude.$error && null"
					invalid-feedback="Should between -180 to 180"
					type="text"
					label="Longitude"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12">
				<hr class="my-5">
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12">
				<h4 class="mb-4">
					Working Info
				</h4>
			</CCol>
		</CRow>
		<CRow class="mb-2">
			<CCol>
				<BusinessHour
					ref="business-hour"
					:business-hours.sync="workingInfo.businessHours"
					title="Working"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12">
				<CTextarea
					v-model.trim="workingInfo.remark"
					rows="3"
					label="Remark"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12">
				<hr class="my-5">
			</CCol>
		</CRow>
		<BaseActionPanelStickyFooter
			:disabled-confirm="disabledPrimaryButton"
			:is-edit="isEditMode"
			content-class="main-wrapper"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: 'WarehouseList'})"
		/>
		<ModalMediaLibrary
			ref="media-library"
			type="single"
			@onSubmit="handleSubmitMediaLibrary"
		/>
		<ModalPostcode
			ref="modal-postcode"
			:list="deliveryInfo.deliveryAreas"
			@onConfirm="handlePostcode"
		/>
	</form>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, email, decimal, between, numeric, minLength } from 'vuelidate/lib/validators';
import BusinessHour from '@/components/BusinessHour.vue';
import ModalMediaLibrary from '@/components/ModalMediaLibrary.vue';
import ModalPostcode from '@/components/ModalPostcode.vue';
import BaseMediaSelectorBox from '@/components/BaseMediaSelectorBox.vue';
import {
	scrollToTop,
	getThumbnailFromMediaObject,
	convertTimeToUTC,
	phoneNumberMustStartWithZero,
	validateNumber,
} from '../assets/js/helpers';
import { DEFAULT_BUSINESS_HOURS } from '../enums/general';


export default {
	name: 'FormWarehouse',
	components: {
		BusinessHour,
		ModalMediaLibrary,
		ModalPostcode,
		BaseMediaSelectorBox,
	},
	validations: {
		generalInfo: {
			nameEn: {
				required,
			},
			nameTh: {
				required,
			},
			branchId: {
				required,
				numeric,
			},
			warehouseType: {
				required,
			},
		},
		addressInfo: {
			addressEn: {
				required,
			},
			addressTh: {
				required,
			},
			postcode: {
				required,
			},
			province: {
				required,
			},
			district: {
				required,
			},
			subdistrict: {
				required,
			},
			phone: {
				required,
			},
			deliveryPhone: {
				required,
				minLength: minLength(9),
				phoneNumberMustStartWithZero(value) {
					return phoneNumberMustStartWithZero(value);
				},
			},
			email: {
				email,
			},
			latitude: {
				decimal,
				between: between(-90, 90),
			},
			longitude: {
				decimal,
				between: between(-180, 180),
			},
		},
	},
	props: {
		isEditMode: {
			type: Boolean,
			default: false,
		},
		disabledPrimaryButton: {
			type: Boolean,
			default: false,
		},
		primaryText: {
			type: String,
			default: 'Create',
		},
		logo: {
			type: Object,
			default: null,
		},
		nameEn: {
			type: String,
			default: null,
		},
		nameTh: {
			type: String,
			default: null,
		},
		branchId: {
			type: String,
			default: null,
		},
		isActive: {
			type: Boolean,
			default: null,
		},
		addressEn: {
			type: String,
			default: null,
		},
		addressTh: {
			type: String,
			default: null,
		},
		postcode: {
			type: [String, Number],
			default: null,
		},
		provinceId: {
			type: [String, Number],
			default: null,
		},
		districtId: {
			type: [String, Number],
			default: null,
		},
		subdistrictId: {
			type: [String, Number],
			default: null,
		},
		phone: {
			type: String,
			default: null,
		},
		deliveryPhone: {
			type: String,
			default: null,
		},
		email: {
			type: String,
			default: null,
		},
		latitude: {
			type: String,
			default: null,
		},
		longitude: {
			type: String,
			default: null,
		},
		remark: {
			type: String,
			default: null,
		},
		deliveryAreas: {
			type: Array,
			default: () => [],
		},
		businessHours: {
			type: Array,
			default: () => DEFAULT_BUSINESS_HOURS,
		},
		warehouseTypes: {
			type: Array,
			default: () => [],
		},
		warehouseTypeId: {
			type: Number,
			default: null,
		},
		storeImage: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			isPrefill: false,
			generalInfo: {
				logo: null,
				nameEn: null,
				nameTh: null,
				branchId: null,
				isActive: false,
				type: null,
			},
			addressInfo: {
				addressEN: null,
				addressTH: null,
				postcode: null,
				province: {},
				district: {},
				subdistrict: {},
				phone: null,
				deliveryPhone: null,
				email: null,
				latitude: null,
				longitude: null,
			},
			workingInfo: {
				businessHours: [],
				remark: null,
			},
			deliveryInfo: {
				deliveryAreas: [],
			},
			file: null,
		};
	},
	computed: {
		...mapGetters({
			getProvinces: 'addresses/getProvinces',
			getAddressInfoByPostcode: 'addresses/getAddressInfoByPostcode',
			getDistrictsByProviceId: 'addresses/getDistrictsByProviceId',
			getSubdistrictsByDistrictId: 'addresses/getSubdistrictsByDistrictId',
			getSubdistrictById: 'addresses/getSubdistrictById',
			getDistrictById: 'addresses/getDistrictById',
			getProvinceById: 'addresses/getProvinceById',
		}),
		provinceOptions() {
			if (!this.$v.addressInfo.province.$dirty) {
				return [];
			}

			return this.getProvinces;
		},
		districtOptions() {
			if (!this.addressInfo.province) {
				return [];
			}
			return this.getDistrictsByProviceId(this.addressInfo.province.id);
		},
		subdistrictOptions() {
			if (!this.addressInfo.district) {
				return [];
			}
			return this.getSubdistrictsByDistrictId(this.addressInfo.district.id);
		},
		branchIdErrors() {
			if (!this.$v.generalInfo.branchId.required) {
				return 'Required field';
			}

			if (!this.$v.generalInfo.branchId.numeric) {
				return 'Branch id should be number';
			}

			return null;
		},
		warehouseTypeOptions() {
			return this.warehouseTypes.map((type) => ({
				name: type.name,
				value: type.id,
			}));
		},
		deliveryPhoneError() {
			const phoneInput = this.$v.addressInfo.deliveryPhone;
			if (!phoneInput.required) {
				return this.$t('global.error.required');
			} else if (!phoneInput.minLength) {
				return this.$t('global.error.minimumLength', { number: 9 });
			} else if (!phoneInput.phoneNumberMustStartWithZero) {
				return this.$t('global.error.phoneNumberMustStartWithZero');
			}

			return null;
		},
	},
	watch: {
		'addressInfo.postcode': function watchPostcode(val) {
			if (val.length !== 5) {
				return;
			}

			if (this.isPrefill) {
				this.$v.addressInfo.province.$touch();
				this.isPrefill = false;
				return;
			}

			const addressInfo = this.getAddressInfoByPostcode(val);

			// If postcode is equal subdistrict postcode don't auto fill
			if (addressInfo && this.addressInfo.subdistrict && this.addressInfo.subdistrict.postcode === addressInfo.subdistrict.postcode) {
				this.$v.addressInfo.province.$touch();
				return;
			}

			if (addressInfo
				&& addressInfo.province
				&& addressInfo.district
				&& addressInfo.subdistrict
			) {
				this.addressInfo.province = addressInfo.province;
				this.addressInfo.district = addressInfo.district;
				this.addressInfo.subdistrict = addressInfo.subdistrict;
				this.$v.addressInfo.province.$touch();
			}
		},

		/**
		 * If districtOptions changed and current subdistrict selection is not in the new list,
		 * reset the selection
		 */
		districtOptions(districts) {
			if (!this.addressInfo.district) {
				return;
			}
			if (districts.every((district) => district.id !== this.addressInfo.district.id)) {
				this.addressInfo.district = null;
			}
		},

		/**
		 * If subdistrictsOptions changed and current subdistrict selection is not in the new list,
		 * reset the selection
		 */
		subdistrictOptions(subdistricts) {
			if (!this.addressInfo.subdistrict) {
				return;
			}
			if (subdistricts.every((subdistrict) => subdistrict.id !== this.addressInfo.subdistrict.id)) {
				this.addressInfo.subdistrict = null;
			}
		},
	},
	async created() {
		this.isPrefill = this.isEditMode;
		this.generalInfo = {
			logo: this.logo,
			nameEn: this.nameEn,
			nameTh: this.nameTh,
			branchId: this.branchId,
			isActive: this.isActive,
			warehouseType: this.warehouseTypeOptions.find((type) => type.value === this.warehouseTypeId),
		};

		this.addressInfo = {
			addressEn: this.addressEn,
			addressTh: this.addressTh,
			postcode: this.postcode && this.postcode.toString(),
			province: this.getProvinceById(this.provinceId),
			district: this.getDistrictById(this.districtId),
			subdistrict: this.getSubdistrictById(this.subdistrictId),
			phone: this.phone,
			deliveryPhone: this.deliveryPhone,
			email: this.email,
			latitude: this.latitude,
			longitude: this.longitude,
		};

		this.workingInfo = {
			businessHours: this.businessHours,
			remark: this.remark,
		};

		this.deliveryInfo = {
			deliveryAreas: this.deliveryAreas,
		};

		this.file = this.storeImage;
	},
	methods: {
		handleSubmitMediaLibrary(images) {
			this.generalInfo.logo = {
				...images[0],
				urls: images[0].imageUrls,
			};
		},
		showLogo(image) {
			return getThumbnailFromMediaObject(image);
		},
		handleDeleteLogo() {
			this.generalInfo.logo = null;
		},
		workingTimeError(obj, index) {
			if (!obj.required) {
				return 'Required field';
			}

			if (!obj.after) {
				return `Should be after ${this.businessHours[index].from}`;
			}

			return null;
		},
		async handleSubmit() {
			const refBusinessHour = this.$refs['business-hour'];
			this.$v.$touch();
			refBusinessHour.$v.$touch();
			if (this.$v.$invalid || refBusinessHour.$v.$invalid) {
				scrollToTop();
				return;
			}

			const params = {
				logo: this.generalInfo.logo && this.generalInfo.logo.id,
				name_en: this.generalInfo.nameEn,
				name_th: this.generalInfo.nameTh,
				com7_branch_id: Number(this.generalInfo.branchId),
				is_active: !!this.generalInfo.isActive,
				address_info: {
					address_en: this.addressInfo.addressEn,
					address_th: this.addressInfo.addressTh,
					postcode: this.addressInfo.postcode,
					province_id: this.addressInfo.province.id,
					district_id: this.addressInfo.district.id,
					subdistrict_id: this.addressInfo.subdistrict.id,
					phone: this.addressInfo.phone,
					delivery_phone_number: this.addressInfo.deliveryPhone,
					email: this.addressInfo.email,
					latitude: this.addressInfo.latitude,
					longitude: this.addressInfo.longitude,
				},
				business_hours: this.workingInfo.businessHours.map((businessHour) => {
					let from;
					let to;

					if (businessHour.isActive) {
						from = businessHour.from ? convertTimeToUTC(businessHour.from) : null;
						to = businessHour.to ? convertTimeToUTC(businessHour.to) : null;
					} else {
						from = null;
						to = null;
					}

					return {
						day: businessHour.day,
						hour: { from, to },
						is_active: businessHour.isActive,
					};
				}),
				remark: this.workingInfo.remark || '',
				postcode_ids: this.deliveryInfo.deliveryAreas,
				branch_brand_type_id: this.generalInfo.warehouseType.value,
				store_image: this.file?.id || null,
			};

			this.$emit('onSubmit', params);
		},
		handlePostcode(postcodes) {
			this.deliveryInfo.deliveryAreas = postcodes;
		},
		onValidateNumber($event) {
			validateNumber($event);
		},
	},
};
</script>

<style lang="scss" scoped>
	.postcode-list {
		overflow-y: auto;
		min-height: rem(60);
		max-height: rem(468);
		padding: rem(20) rem(16);
		border-radius: rem(4);
		border: solid 1px $color-gray-400;
		list-style: none;

		.list-item {
			border-radius: rem(4);
			background-color: $color-gray-300;
			color: $color-black-90;
			display: inline-block;
			padding: 0 rem(6);
			margin-right: rem(12);
			margin-bottom: rem(12);
		}
	}
</style>
